.product-list {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
}
.product-list-div {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
  align-items: center;
  margin: 10px;
}
.product-list-div h4 {
  font-size: 0.9em;
  color: var(--heading-color);
}
.product-list-div p {
  color: var(--text-color);
  font-size: 0.9em;
}
.product-list-div .assigned-to {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eee;
  display: table-cell;
  vertical-align: middle

}
.product-list-div ul {
  margin: 10px 0;
  display: flex;
}
.product-list-div li {
  margin-right: 10px;
}
.product-list-div .avatar {
  width: 30px;
  height: 30px;
}

.button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  width: 200px;
  height: 70px;

}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.button:hover {
  background-color: #3e8e41;
}

.buttondiv {
  display: flex;
  flex-wrap: wrap;
  text-align: center;

}