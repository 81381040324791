@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* variables */
:root {
  --heading-color: #444;
  --text-color: #999;
  --primary-color: #19171A;
  --highlight-color: #05F4B7;
  --bg-color: #f4f4f4;
}

/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
  background: #f4f4f4;
  background: var(--bg-color);
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}

/* layout */
.page-title {
  font-size: 1em;
  color: #444;
  color: var(--heading-color);
  display: inline-block;
}
.btn {
  background: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  color: #19171A;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid #19171A;
  border: 1px solid var(--primary-color);
}
.btn:hover {
  color: #fff;
  background-color: #19171A;
  background-color: var(--primary-color);
}

/* forms */
label {
  display: block;
  margin: 24px auto;
}
label span {
  display: block;
  margin-bottom: 6px;
}
input, textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}
textarea {
  min-height: 160px;
}
.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}
.App {
  display: flex;
}
.App .container{
  flex-grow: 1;
  padding: 0 60px;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
  }
  .modal {
    padding: 30px;
    max-width: 480px;
    margin: 200px auto;
    background: #fff;
    border-radius: 10px;
    text-align: center;
  }
  .btn {
    background: #fff;
    padding: 8px 12px;
    border-radius: 4px;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 1em;
    border: 1px solid var(--primary-color);
  }
  .btn:hover {
    color: #fff;
    background-color: var(--primary-color);
  }

  .btn_close {
    background: #fff;
    padding: 4px 6px;
    border-radius: 4px;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 0.6em;
    border: 1px solid var(--primary-color);
  }
  .btn_close:hover {
    color: #fff;
    background-color: var(--primary-color);
  }
.product-list {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
}
.product-list-div {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
  align-items: center;
  margin: 10px;
}
.product-list-div h4 {
  font-size: 0.9em;
  color: var(--heading-color);
}
.product-list-div p {
  color: var(--text-color);
  font-size: 0.9em;
}
.product-list-div .assigned-to {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eee;
  display: table-cell;
  vertical-align: middle

}
.product-list-div ul {
  margin: 10px 0;
  display: flex;
}
.product-list-div li {
  margin-right: 10px;
}
.product-list-div .avatar {
  width: 30px;
  height: 30px;
}

.button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  width: 200px;
  height: 70px;

}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.button:hover {
  background-color: #3e8e41;
}

.buttondiv {
  display: flex;
  flex-wrap: wrap;
  text-align: center;

}
.product-filter {
  margin: 30px auto;
}
.product-filter nav {
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}
.product-filter p {
  font-size: 0.9em;
  margin-right: 10px;
}
.product-filter button {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-weight: bold;
  color: var(--text-color);
  cursor: pointer;
  border-right: 1px solid #e4e4e4;
  font-size: 0.9em;
}
.product-filter button:last-child {
  border: 0;
}
.product-filter button.active {
  color: var(--primary-color);
} 
.create-form {
  max-width: 600px;
}

.auth-form {
  max-width: 360px;
  margin: 60px auto;
  padding: 40px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
  background: #fff;
}
.product-details {
  display: grid;
  align-items: start;
  grid-gap: 60px;
}

/* product summary */
.product-summary {
  background-color: #fff;
  padding: 30px;
  border-radius: 4px;
}
.product-summary .due-date {
  margin: 10px 0;
  font-size: 0.9em;
  color: var(--title-color);
}
.product-summary .details {
  margin: 30px 0;
  color: var(--text-color);
  line-height: 1.8em;
  font-size: 0.9em;
}
.product-summary h4 {
  color: var(--text-color);
  font-size: 0.9em;
}
.product-summary .assigned-users {
  display: flex;
  margin-top: 20px;
}
.product-summary .assigned-users .avatar {
  margin-right: 10px;
}
.product-summary + .btn {
  margin-top: 20px;
}

/* product comments */
.product-comments label {
  margin-bottom: 0px;
}
.product-comments textarea {
  min-height: 40px;
  font-size: 1.5em;
}

/* comment list */
.product-comments h4 {
  color: var(--heading-color);
}
.product-comments li {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  margin-top: 20px;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
  background: #fff;
}
.comment-author {
  display: flex;
  align-items: center;
  color: var(--title-color);
}
.comment-author .avatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.comment-date {
  color: var(--text-color);
  font-size: 0.9em;
  margin: 4px 0 10px;
}
.comment-content {
  color: black;
  font-size: 0.9em;
}

/* comments scrollbar */
.product-comments {
  max-height: 800px;
  overflow: auto;
  padding-right: 20px;
  /* firefox scrollbar style */
  scrollbar-width: 20px;
  scrollbar-color: #fff #e4e4e4;
}
/* Chrome, Edge, and Safari scrollbar styles */
.product-comments::-webkit-scrollbar {
  width: 6px;
}
.product-comments::-webkit-scrollbar-track {
  background: #fff;
}
.product-comments::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 3px solid #e4e4e4;
}
.navbar {
  width: 100%;
  padding: 30px 0;
  box-sizing: border-box;
  margin-bottom: 80px;
}
.navbar ul {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-end;
}
.navbar .logo {
  font-weight: bold;
  color: var(--heading-color);
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  margin-right: auto;
}
.navbar .logo img {
  margin-right: 10px;
  -webkit-filter: invert(25%);
          filter: invert(25%);
  width: 36px;
  margin-top: -8px;
}
.navbar a {
  color: #333;
  text-decoration: none;
  margin-right: 20px;
}
.sidebar {
  width: 300px;
  min-width: 300px;
  background: var(--primary-color);
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  color: #fff;
}
.sidebar-content {
  position: fixed;
  width: inherit;
}
.sidebar .user {
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  padding: 40px 30px;
  border-bottom: 1px solid rgba(255,255,255,0.2);
  font-size: 0.6em;
}
.sidebar .links {
  margin-top: 80px;
  margin-left: 20px;
}
.sidebar .links li {
  margin-top: 10px;
}
.sidebar .links a {
  display: flex;
  padding: 10px;
  text-decoration: none;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
}
.sidebar .links img {
  margin-right: 10px;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
.sidebar .links a.active {
  color: #555;
  background: var(--bg-color);
  border-radius: 20px 0 0 20px;
}
.sidebar .links .active img {
  -webkit-filter: invert(40%);
          filter: invert(40%);
}

/* avatar in sidebar */
.sidebar .user .avatar {
  margin-bottom: 10px;
  width: 70px;
  height: 70px;
  border: 3px solid #fff;
}
@media(max-width: 767px){.desktop-only{display:none}}
