.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
  }
  .modal {
    padding: 30px;
    max-width: 480px;
    margin: 200px auto;
    background: #fff;
    border-radius: 10px;
    text-align: center;
  }
  .btn {
    background: #fff;
    padding: 8px 12px;
    border-radius: 4px;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 1em;
    border: 1px solid var(--primary-color);
  }
  .btn:hover {
    color: #fff;
    background-color: var(--primary-color);
  }

  .btn_close {
    background: #fff;
    padding: 4px 6px;
    border-radius: 4px;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 0.6em;
    border: 1px solid var(--primary-color);
  }
  .btn_close:hover {
    color: #fff;
    background-color: var(--primary-color);
  }